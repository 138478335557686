





























import { Component, Ref, Vue } from 'vue-property-decorator'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Qriously from 'vue-qriously'
import { uploadFilesPublicToS3 } from '@/shareds/s3/files'
import { AnexoUseCase } from '@/usecases'
import { TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'

Vue.use(Qriously, { name:"qriously"})

@Component
export default class AnexarArquivo extends Vue {
	@Ref() uploadForm!: HTMLFormElement

	loading = false
	arquivos: File[] = []
	anexoId: string | null = null
	identificadorDoProcesso = this.$route.params.chaveOuIdentificador
	tipoDeProcesso = this.$route.params.tipoDeProcesso as TipoDeProcesso
	usuarioId = this.$route.params.usuarioId

	anexoUseCase = new AnexoUseCase()

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	async created() {
		await this.anexoUseCase.findAnexoIdByIdentificadorPublico(this.identificadorDoProcesso, this.usuarioId)
			.then(idAnexo => this.anexoId = idAnexo)
			.catch(e => AlertModule.setError(e))
			.finally(() => this.loading = false)
	}

	async upload() {
		if(!this.tipoDeProcesso) {
			throw new Error("Ocorreu um erro interno, contate o suporte")
		}
		if (!this.arquivos || this.arquivos.length === 0) {
			throw new Error("Selecione pelo menos um arquivo para anexar")
		}

		const pattern = /[~`!@#$%^&*()áÁàÀçÇõÕãÃôÔêÊ\-+={}[\]|\\;:'",<>/?]+/g;
		const espaco = /\s+/g;

		if (this.arquivos.length > 0 && (pattern.test(this.arquivos[0].name) || espaco.test(this.arquivos[0].name))) {
			const anexo = this.arquivos[0];
			let url = anexo.name.replace(espaco, '_');

			url = url.replace(pattern, '');
			
			const anexoFormatado = new File([anexo], url, {
				type: anexo.type,
				lastModified: anexo.lastModified,
			});
			this.arquivos[0] = anexoFormatado;
		}

		try {
			this.loading = true
			const informacoesDosArquivos = await uploadFilesPublicToS3(
				this.arquivos, `anexo/${this.tipoDeProcesso}/${this.identificadorDoProcesso}`, this.usuarioId,
			)

			const arquivosComExtensao = informacoesDosArquivos.map(
				({ config }) => config.url?.split("?")[0] || "",
			).filter(url => url && url.length > 0)

			await this.anexoUseCase.createOuUpdatePublico({
				id: this.anexoId,
				urlDosArquivos: arquivosComExtensao,
				idDoProcesso: this.identificadorDoProcesso,
				tipoDeProcesso: this.tipoDeProcesso,
			},
			this.usuarioId)

			AlertModule.setSuccess('Arquivo(s) anexado(s) com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.arquivos = []
			this.loading = false
		}
	}
}
